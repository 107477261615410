import { Box, Divider, Flex, NumberInput, NumberInputField, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  DiscoveryState,
  FilterInterval,
  setBoardTotalFrom,
  setBoardTotalTo,
  setDiverseOwnership,
  setEmployeesFrom,
  setEmployeesGrowthFrom,
  setEmployeesGrowthTo,
  setEmployeesTo,
  setLeadershipTeamTotalFrom,
  setLeadershipTeamTotalTo,
  setRevenuePerEmployeeFrom,
  setRevenuePerEmployeeTo,
} from '../../stores/features';
import { Checkbox } from '../GenericComponents';

const DiversityAndEmployeesFilters = () => {
  const { t } = useTranslation();

  return (
    <Box p="1.5rem" maxWidth="50vw">
      <Text pl="2" as="h2" textStyle="h2">
        {t('Diversity & Employees')}
      </Text>
      <Divider m="12px 0" />
      <Flex>
        <Box flex="4" pl="2">
          <DiversityFilters />
          <Stack spacing={3} direction="row">
            <Box flex="1">
              <BoardFilters />
            </Box>
            <Box flex="1">
              <LeadershipFilters />
            </Box>
          </Stack>
          <Box width="50%">
            <EmployeeGrowthFilters />
          </Box>
        </Box>
        <Box flex="2" pl="10">
          <EmployeesFilters />
        </Box>
      </Flex>
    </Box>
  );
};

export const DiversityFilters = () => {
  const { t } = useTranslation();
  const diverseOwnership = useSelector((state: { discovery: DiscoveryState }) => state.discovery?.diverseOwnership);
  const dispatch = useDispatch();
  const handleToggleItem = (target: string) => {
    const newItems =
      diverseOwnership.indexOf(target) > -1
        ? diverseOwnership.filter((item) => item !== target)
        : [...diverseOwnership, target];
    dispatch(setDiverseOwnership(newItems));
  };

  return (
    <>
      <Text as="h4" textStyle="h4">
        {t('Diversity')}
      </Text>
      <Flex direction="row" mt=".5rem">
        <Stack flex={1} spacing={3} direction="column">
          <Stack direction="row" alignItems="center" onClick={() => handleToggleItem('Minority')}>
            <Checkbox checked={diverseOwnership.indexOf('Minority') > -1} />
            <Text textStyle="body">{t('Minority')}</Text>
          </Stack>
          <Stack direction="row" alignItems="center" onClick={() => handleToggleItem('Veteran')}>
            <Checkbox checked={diverseOwnership.indexOf('Veteran') > -1} />
            <Text textStyle="body">{t('Veteran')}</Text>
          </Stack>
          <Stack direction="row" alignItems="center" onClick={() => handleToggleItem('Disabled')}>
            <Checkbox checked={diverseOwnership.indexOf('Disabled') > -1} />
            <Text textStyle="body">{t('Disabled')}</Text>
          </Stack>
          <Stack direction="row" alignItems="center" onClick={() => handleToggleItem('B-Corp')}>
            <Checkbox checked={diverseOwnership.indexOf('B-Corp') > -1} />
            <Text textStyle="body">{t('B-Corp')}</Text>
          </Stack>
        </Stack>
        <Stack flex={1} spacing={3} direction="column">
          <Stack direction="row" alignItems="center" onClick={() => handleToggleItem('Women')}>
            <Checkbox checked={diverseOwnership.indexOf('Women') > -1} />
            <Text textStyle="body">{t('Women')}</Text>
          </Stack>
          <Stack direction="row" alignItems="center" onClick={() => handleToggleItem('LGBTQ')}>
            <Checkbox checked={diverseOwnership.indexOf('LGBTQ') > -1} />
            <Text textStyle="body">{t('LGBTQ')}</Text>
          </Stack>
          <Stack direction="row" alignItems="center" onClick={() => handleToggleItem('Disadvantaged')}>
            <Checkbox checked={diverseOwnership.indexOf('Disadvantaged') > -1} />
            <Text textStyle="body">{t('Disadvantaged')}</Text>
          </Stack>
          <Stack direction="row" alignItems="center" onClick={() => handleToggleItem('Native American')}>
            <Checkbox checked={diverseOwnership.indexOf('Native American') > -1} />
            <Text textStyle="body">{t('Native American')}</Text>
          </Stack>
          {/* <Stack direction="row" alignItems="center" onClick={() => handleToggleItem('Immigrant')}>
            <Checkbox checked={diverseOwnership.indexOf('Immigrant') > -1} />
            <Text textStyle="body">{t('Immigrant')}</Text>
          </Stack> */}
        </Stack>
      </Flex>
    </>
  );
};

export const BoardFilters = () => {
  const { t } = useTranslation();
  const boardTotal: FilterInterval = useSelector((state: { discovery: DiscoveryState }) => state.discovery?.boardTotal);
  const dispatch = useDispatch();
  return (
    <>
      <Text mt="0.5rem" as="h5" textStyle="h5" fontWeight="400" lineHeight="18px">
        {t('Board')}
      </Text>
      <Flex alignItems="center" marginTop=".2rem" marginBottom="1.2rem">
        <NumberInput
          bg="#fff"
          size="sm"
          flex="1"
          value={!isNaN(Number(boardTotal?.from)) ? boardTotal?.from : ''}
          onChange={(valueAsString, valueAsNumber) => {
            dispatch(setBoardTotalFrom(valueAsNumber));
          }}
          max={boardTotal?.to}
          min={Number(0)}
        >
          <NumberInputField borderRadius="4px" placeholder={t('% Min')} />
        </NumberInput>
        <Divider w="3" m="1" />
        <NumberInput
          bg="#fff"
          size="sm"
          flex="1"
          value={!isNaN(Number(boardTotal?.to)) ? boardTotal?.to : ''}
          onChange={(valueAsString, valueAsNumber) => {
            dispatch(setBoardTotalTo(valueAsNumber));
          }}
          min={Number(boardTotal?.from)}
        >
          <NumberInputField borderRadius="4px" placeholder={t('% Max')} />
        </NumberInput>
      </Flex>
    </>
  );
};

export const LeadershipFilters = () => {
  const { t } = useTranslation();
  const leadershipTeamTotal: FilterInterval = useSelector(
    (state: { discovery: DiscoveryState }) => state.discovery?.leadershipTeamTotal
  );
  const dispatch = useDispatch();

  return (
    <>
      <Text mt="0.5rem" as="h5" textStyle="h5" fontWeight="400" lineHeight="18px">
        {t('Leadership')}
      </Text>
      <Flex alignItems="center" marginTop=".2rem" marginBottom="1.2rem">
        <NumberInput
          bg="#fff"
          size="sm"
          flex="1"
          value={!isNaN(Number(leadershipTeamTotal?.from)) ? leadershipTeamTotal?.from : ''}
          onChange={(valueAsString, valueAsNumber) => {
            dispatch(setLeadershipTeamTotalFrom(valueAsNumber));
          }}
          max={leadershipTeamTotal?.to}
          min={Number(0)}
        >
          <NumberInputField borderRadius="4px" placeholder={t('% Min')} />
        </NumberInput>
        <Divider w="3" m="1" />
        <NumberInput
          bg="#fff"
          size="sm"
          flex="1"
          value={!isNaN(Number(leadershipTeamTotal?.to)) ? leadershipTeamTotal?.to : ''}
          onChange={(valueAsString, valueAsNumber) => {
            dispatch(setLeadershipTeamTotalTo(valueAsNumber));
          }}
          min={Number(leadershipTeamTotal?.from)}
        >
          <NumberInputField borderRadius="4px" placeholder={t('% Max')} />
        </NumberInput>
      </Flex>
    </>
  );
};

export const EmployeeGrowthFilters = () => {
  const { t } = useTranslation();
  const employeesGrowth: FilterInterval = useSelector(
    (state: { discovery: DiscoveryState }) => state.discovery?.employeesGrowth
  );
  const dispatch = useDispatch();

  return (
    <>
      <Text mt="0.5rem" as="h5" textStyle="h5" fontWeight="400" lineHeight="18px">
        {t('Employees')}
      </Text>
      <Flex alignItems="center" marginTop=".2rem" marginBottom="1.2rem">
        <NumberInput
          bg="#fff"
          size="sm"
          flex="1"
          value={!isNaN(Number(employeesGrowth?.from)) ? employeesGrowth?.from : ''}
          onChange={(valueAsString, valueAsNumber) => {
            dispatch(setEmployeesGrowthFrom(valueAsNumber));
          }}
          max={employeesGrowth?.to}
          min={Number(0)}
        >
          <NumberInputField borderRadius="4px" placeholder={t('% Min')} />
        </NumberInput>
        <Divider w="3" m="1" />
        <NumberInput
          bg="#fff"
          size="sm"
          flex="1"
          value={!isNaN(Number(employeesGrowth?.to)) ? employeesGrowth?.to : ''}
          onChange={(valueAsString, valueAsNumber) => {
            dispatch(setEmployeesGrowthTo(valueAsNumber));
          }}
          min={Number(employeesGrowth?.from)}
        >
          <NumberInputField borderRadius="4px" placeholder={t('% Max')} />
        </NumberInput>
      </Flex>
    </>
  );
};

export const EmployeesFilters = () => {
  const { t } = useTranslation();
  const employees: FilterInterval = useSelector((state: { discovery: DiscoveryState }) => state.discovery?.employees);
  const revenuePerEmployee: FilterInterval = useSelector(
    (state: { discovery: DiscoveryState }) => state.discovery?.revenuePerEmployee
  );
  const dispatch = useDispatch();

  return (
    <>
      <Text as="h4" textStyle="h4">
        {t('Employees')}
      </Text>
      <Text mt="0.5rem" as="h5" textStyle="h5" fontWeight="400" lineHeight="18px">
        {t('Number of Employees')}
      </Text>
      <Flex alignItems="center" marginTop=".2rem" marginBottom="1.2rem">
        <NumberInput
          bg="#fff"
          size="sm"
          flex="1"
          value={!isNaN(Number(employees?.from)) ? employees?.from : ''}
          onChange={(valueAsString, valueAsNumber) => {
            dispatch(setEmployeesFrom(valueAsNumber > 0 ? valueAsNumber : undefined));
          }}
          max={employees?.to}
          min={Number(0)}
        >
          <NumberInputField borderRadius="4px" placeholder={t('Min')} />
        </NumberInput>
        <Divider w="3" m="1" />
        <NumberInput
          bg="#fff"
          size="sm"
          flex="1"
          value={!isNaN(Number(employees?.to)) ? employees?.to : ''}
          onChange={(valueAsString, valueAsNumber) => {
            dispatch(setEmployeesTo(valueAsNumber));
          }}
          min={Number(employees?.from)}
        >
          <NumberInputField borderRadius="4px" placeholder={t('Max')} />
        </NumberInput>
      </Flex>
      <Text mt="0.5rem" as="h5" textStyle="h5" fontWeight="400" lineHeight="18px">
        {t('Revenue per employee')}
      </Text>
      <Flex alignItems="center" marginTop=".2rem" marginBottom="1.2rem">
        <NumberInput
          bg="#fff"
          size="sm"
          flex="1"
          value={!isNaN(Number(revenuePerEmployee?.from)) ? revenuePerEmployee?.from : ''}
          onChange={(valueAsString, valueAsNumber) => {
            dispatch(setRevenuePerEmployeeFrom(valueAsNumber));
          }}
          max={revenuePerEmployee?.to}
          min={Number(0)}
        >
          <NumberInputField borderRadius="4px" placeholder={t('$ Min')} />
        </NumberInput>

        <Divider w="3" m="1" />
        <NumberInput
          bg="#fff"
          size="sm"
          flex="1"
          value={!isNaN(Number(revenuePerEmployee?.to)) ? revenuePerEmployee?.to : ''}
          onChange={(valueAsString, valueAsNumber) => {
            dispatch(setRevenuePerEmployeeTo(valueAsNumber));
          }}
          min={Number(revenuePerEmployee?.from)}
        >
          <NumberInputField borderRadius="4px" placeholder={t('$ Max')} />
        </NumberInput>
      </Flex>
    </>
  );
};

export default DiversityAndEmployeesFilters;
