import {
  Box,
  Button,
  HStack,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import Plot from 'react-plotly.js';
import React from 'react';
import { companiesName, dummyInsights } from './DummyAiGrantData';
interface CustomListItemProps {
  companyName?: string;
  suggestion: string;
}

const AiSuggestion = () => {
  return (
    <Box h="500px" border="1px solid #E4E4E4" rounded="4px" overflowY="scroll" p="15px" w="400px">
      <Stack isInline>
        <Box>
          <Text cursor="pointer" ml="6px" fontSize="16px" fontWeight="600">
            Insights
          </Text>
        </Box>
      </Stack>
      <Stack isInline>
        <Box fontSize="16px" pt="18px" px="10px">
          <UnorderedList>
            {dummyInsights.map((insight) => (
              <CustomListItem companyName={insight.companyName} suggestion={insight.suggestion} />
            ))}
          </UnorderedList>
        </Box>
      </Stack>
    </Box>
  );
};

const CustomListItem: React.FC<CustomListItemProps> = ({ companyName, suggestion }) => (
  <ListItem my="14px">{companyName && <InsightsModel companyName={companyName} suggestion={suggestion} />}</ListItem>
);
export default AiSuggestion;

interface Data {
  Company: string[];
  Revenue: number[];
  Profit: number[];
  MarketCap: number[];
}

interface DataFrame extends Data {
  Revenue_Norm: number[];
}

export const ScatterPlot3D = () => {
  // Sample data

  const data: Data = {
    Company: Array.from({ length: 20 }, (_, i) => companiesName[i]),
    Revenue: Array.from({ length: 20 }, () => Math.floor(Math.random() * 1500) + 500),
    Profit: Array.from({ length: 20 }, () => Math.floor(Math.random() * 280) + 20),
    MarketCap: Array.from({ length: 20 }, () => Math.floor(Math.random() * 9000) + 1000),
  };

  const df: DataFrame = {
    ...data,
    Revenue_Norm: data.Revenue.map(
      (r) => (r - Math.min(...data.Revenue)) / (Math.max(...data.Revenue) - Math.min(...data.Revenue))
    ),
  };

  // Define the trace for the 3D scatter plot
  const truncatedText = df.Company.map((company) => (company.length > 10 ? `${company.substring(0, 10)}...` : company));

  const trace: Partial<Plotly.ScatterData> & { z: number[]; type: 'scatter3d' } = {
    x: df.Revenue,
    y: df.Profit,
    z: df.MarketCap,
    mode: 'text+markers',
    text: truncatedText, // Truncated text shown on the scatter plot
    textposition: 'top center',
    hovertext: df.Company, // Full company name shown on hover for each marker
    hovertemplate: `
      <b>%{hovertext}</b><br> 
      Revenue: %{x}<br>
      Profit: %{y}<br>
      Market Cap: %{z}<br>
      <extra></extra>
    `,
    marker: {
      size: 8,
      color: df.Revenue_Norm,
      colorscale: 'Viridis',
      opacity: 0.8,
    },
    type: 'scatter3d',
  };
  const layout: Partial<Plotly.Layout> = {
    scene: {
      xaxis: { title: 'Revenue' },
      yaxis: { title: 'Profit' },
      zaxis: { title: 'Market Cap' },
      camera: {
        eye: { x: 1.5, y: 1.5, z: 1.5 }, // Position of the camera
        center: { x: 0, y: 0, z: 0 }, // The point the camera is looking at
        up: { x: 0, y: 0, z: 1 }, // The “up” direction of the camera
      },
    },
    margin: {
      l: 0,
      r: 0,
      b: 0,
      t: 0,
    },
    width: 600, // Adjust the width of the chart
    height: 460, // Adjust the height of the chart
    hoverlabel: {
      align: 'left', // Align the hover label text to the left
      namelength: -1, // Show the full text without truncation
    },
  };

  return (
    <Box h="500px" border="1px solid #E4E4E4" rounded="4px" overflowY="scroll" p="15px" w="654px">
      <Stack isInline>
        <Box>
          <Text ml="6px" fontSize="16px" fontWeight="600">
            Supplier Intelligence Map
          </Text>
        </Box>
      </Stack>
      <Plot
        data={[trace]} // Plotly.js expects data as an array
        layout={layout}
        config={{ displayModeBar: true }}
        className="vertical-modebar"
      />
    </Box>
  );
};

export const InsightsModel = (props: { companyName: string; suggestion: string }) => {
  const { companyName, suggestion } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <Text fontSize="14px" lineHeight="25px">
        <Text
          as="span"
          bg="#F1F1F1"
          color="#2A2A28"
          lineHeight="20px"
          borderRadius="4px"
          p="3px 8px"
          fontFamily="poppins"
          mr="2px"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          maxWidth="calc(5ch + 8px)"
          onClick={onOpen}
          cursor="pointer" // Added cursor to indicate clickable element
        >
          {companyName.length > 15 ? `${companyName.substring(0, 15)}...` : companyName}
        </Text>
        {suggestion}
      </Text>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="rgba(0, 0, 0, 0.3)" />
        <ModalContent border="1px solid #E4E4E4" rounded="4px" boxShadow="0px 1px 2px rgba(0, 0, 0, 0.25)">
          <ModalHeader>Insights</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="14px" fontWeight="800">
              {companyName}
            </Text>
            <UnorderedList mt="6px">
              <ListItem fontSize="13px" p="2px">
                {suggestion}
              </ListItem>
            </UnorderedList>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="auto" w="100%">
              <Button colorScheme="green" size="sm" onClick={onClose} disabled>
                Add To Profile
              </Button>
              <Button colorScheme="green" size="sm" onClick={onClose} disabled>
                Next Steps Suggestions
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
